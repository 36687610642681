import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";

// import DumpQL from "../components/Utilities/DumpQL.js";
import Navigation from "../components/Navigation/";
import usagicon from "../images/usagi.ico"

import style from "../styles/global.module.css";

export default ({data}) => {
  return (
    <div className={style.wrap}>
      <Helmet>
          <title>norweijian.</title>
          <link rel="icon" href={usagicon} />
      </Helmet>
      <Navigation />
      <main className={style.maincontainer}>
        <div className={style.postcontainer}>
        {data.allMdx.nodes.map(({excerpt, frontmatter, slug}) => (
          <div className={style.postentry}>
            <Link to={`/${slug}`}><h1>{frontmatter.title}</h1></Link>
            <span>{frontmatter.date}</span>
            <p>{excerpt}</p>
          </div>
        ))}
        </div>
      </main>
    </div>
  )
}

export const query = graphql`
  query allPostsQuery {
    allMdx(
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {frontmatter: {published: {eq: true}}}
    ) {
      nodes {
        id
        excerpt(pruneLength: 250)
        slug
        frontmatter {
          title
          date(formatString: "YYYY MMMM Do")
        }
      }
    }
  }
`